import gql from "graphql-tag";
import { AutoEstimationFields } from "../Fragments/AutoEstimation";
import { MachineRateFields, MaterialRateFields } from "../Fragments/ShopRate";
/*
We have two similar queries. One was for auto estimation progress for order items specifically 
and this new one is to get the progress of a singular Auto Estimation for a job. We should find a way to consolidate
*/
export const AUTO_ESTIMATION_PROGRESS = gql`
  query autoEstimation($id: Int!) {
    autoEstimation(id: $id) {
      ...autoEstimationFields
    }
  }
  ${AutoEstimationFields}
`;

export const AUTO_ESTIMATIONS_PROGRESS = gql`
  query autoEstimations($ids: [Int!]!) {
    autoEstimations(ids: $ids) {
      ...autoEstimationFields
      orderItem {
        id
        price
        estimatedMaterialCubicMm
        estimatedSupportCubicMm
        estimatedMaterialGrams
        estimatedSupportGrams
        estimatedMaterialSquareMm
        estimatedMaterialMm
        estimatedMinutes
        machineRateId
        machineRate {
          ...machineRateFields
        }
        materialRateId
        materialRate {
          ...materialRateFields
        }
        shopMaterialId
        shopMaterialColorId
        shopTechnologyId
        autoEstimationId
        order {
          id
          price
        }
      }
    }
  }
  ${AutoEstimationFields}
  ${MachineRateFields}
  ${MaterialRateFields}
`;
