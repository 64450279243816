import { IOrderItem, UpdatableOrderItemFields } from "@/graphql/Fragments/Order";
import gql from "graphql-tag";
import { MachineRateFields, MaterialRateFields } from "../Fragments/ShopRate";
import { AutoEstimationFields } from "../Fragments";

// Make fields optional/undefined or nullable
type PartialOrNull<T> = {
  [P in keyof T]?: T[P] | null;
};

// Must match the server typedef "input OrderItemUpdateInput"
type OrderItemUpdateInput = PartialOrNull<
  Pick<
    IOrderItem,
    | "quantity"
    | "price" // TODO: Remove or rename as `previewPrice` for error checking on server?
    | "status"
    | "units"
    | "shopTechnologyId"
    | "shopMaterialId"
    | "shopMaterialColorId"
    | "estimatedMinutes"
    | "estimatedMaterialCubicMm"
    | "estimatedMaterialGrams"
    | "estimatedMaterialMm"
    | "estimatedMaterialSquareMm"
    | "estimatedSupportCubicMm"
    | "estimatedSupportGrams"
    | "machineRateId"
    | "materialRateId"
    | "markupPrice"
    | "extraCost"
    | "overridePrice"
    | "autoEstimationId"
    | "orientation"
  >
>;

/** Variables/input for updateOrderItem() UPDATE_ORDER_ITEM mutation  */
export type UpdateOrderItemType = OrderItemUpdateInput;

export const UPDATE_ORDER_ITEM_STATUS = gql`
  mutation updateOrderItemShopStatus($id: Int!, $shopStatus: Int!) {
    updateOrderItemShopStatus(id: $id, shopStatus: $shopStatus) {
      id
      shopStatus
    }
  }
`;

export const UPDATE_ORDER_ITEMS_STATUS = gql`
  mutation updateOrderItemsShopStatus($ids: [Int!]!, $shopStatus: Int!) {
    updateOrderItemsShopStatus(ids: $ids, shopStatus: $shopStatus) {
      id
      shopStatus
    }
  }
`;

export const UPDATE_ORDER_ITEM = gql`
  mutation UpdateOrderItemMutation($id: Int!, $input: OrderItemUpdateInput!) {
    updateOrderItem(id: $id, input: $input) {
      id
      price
      quantity
      ...updatableOrderItemFields
      order {
        id
        price
      }
      autoEstimations {
        ...autoEstimationFields
      }
      machineRate {
        ...machineRateFields
      }
      materialRate {
        ...materialRateFields
      }
    }
  }
  ${UpdatableOrderItemFields}
  ${MachineRateFields}
  ${MaterialRateFields}
  ${AutoEstimationFields}
`;

export const SPLIT_ORDER_ITEMS = gql`
  mutation splitOrderItems($input: SplitOrderItemsInput!) {
    splitOrderItems(input: $input) {
      id
    }
  }
`;

export type UpdateOrderItemVariables = { id: number; input: UpdateOrderItemType };
export type UpdateOrderItemData = { updateOrderItem: UpdateOrderItemResult };
export type UpdateOrderItemResult = Partial<IOrderItem>;

export const UPDATE_ORDER_ITEM_DEPENDENCIES = gql`
  mutation updateOrderItemDependencies($id: Int!, $input: OrderItemDependenciesInput!) {
    updateOrderItemDependencies(id: $id, input: $input) {
      id
    }
  }
`;

export const REMOVE_ORDER_ITEMS_DEPENDENCIES = gql`
  mutation removeOrderItemDependencies($id: Int!, $input: RemoveOrderItemDependenciesInput!) {
    removeOrderItemDependencies(id: $id, input: $input) {
      id
    }
  }
`;
